import React from 'react';
import {
  Router,
  Route,
} from 'react-router-dom';
import { createBrowserHistory } from 'history';
import Markdown from 'react-markdown';

import CookiesBanner from 'frontend-common/src/Components/CookiesBanner/CookiesBanner';
import GTag from 'frontend-common/src/Components/GTag/GTag';
import MetadataContext from 'frontend-common/src/contexts/metadata';

import About from './Screens/About';
import Dashboard from './Screens/Dashboard';
import Home from './Screens/Home';
import NewJob from './Screens/NewJob';
import Privacy from './Screens/Privacy';
import ToS from './Screens/ToS';

import settings from './content/settings/settings.json';
import about from './content/screens/about.json';
import privacy from './content/screens/privacy.json';
import tos from './content/screens/tos.json';

const history = createBrowserHistory();

const metadata = {
  contacts: settings.contacts,
  footerMessage: (
    <Markdown>{ settings.footer }</Markdown>
  ),
  links: [
    about.published && {
      route: '/about',
      title: about.title,
    },
    tos.published && {
      route: '/tos',
      title: tos.title,
    },
    privacy.published && {
      route: '/privacy',
      title: privacy.title,
    },
  ].filter(x => !!x),
  logo: '../static/media/limbo_logo_simple_white.svg',
  siteTitle: settings.title,
};

const App = () => {
  return (
    <MetadataContext.Provider
      value={ metadata }
    >
      <GTag />
      <CookiesBanner />
      <Router history={ history }>
        <Route exact path="/" component={ Home } />
        { about.published && <Route exact path="/about" component={ About } /> }
        <Route exact path="/dashboard" component={ Dashboard } />
        <Route exact path="/newjob" component={ NewJob } />
        { privacy.published && <Route exact path="/privacy" component={ Privacy } /> }
        { tos.published && <Route exact path="/tos" component={ ToS } /> }
      </Router>
    </MetadataContext.Provider>
  );
};

export default App;
